import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Modal } from "flowbite";

// Configurations for upload types
const uploadItems = {
  csv: { title: "Upload CSV" },
  manually: { title: "Enter Prospect Details" },
  url: { title: "Upload LinkedIn Profiles" }
};

const alertMessages = {
  empty: 'Please check, the input is empty',
  max: 'You have only an upload limit of 5000 lines per time',
  verify: 'Your email is unverified. Please check your inbox or spam folder and click the verification link. Only verified accounts can add prospects.',
  credit: 'You have exhausted all the credits. Buy more to continue adding records.',
  error: '',
  warning: '',
}

export default class extends Controller {
  static targets = [
    "radio", "button", "modal", "input", "url", "dropzone",
    "tableHeader", "tableBody", "preview",
    "manuallySection", "csvSection", "urlSection",
    "message", "lineCounter", "lineCount", "submitButton",
    "alertMessage", "error", "warning", "alert",
    "total", "credit", "progress", "source"
  ];
  static values = {
    uploadType: String,
    defaultType: String,
    availableCredits: Number,
    isPaid: Boolean,
    isVerified: Boolean,
  };

  connect() {
    try {
      this.selectedItem = uploadItems[this.uploadTypeValue || "csv"];
      this.initModal = this.initModal.bind(this);
      this.modal = this.initModal();
      this.alert = this.initAlertModal();
      this.listModal = this.initListModal();
      this.alertType = null;
      this.totalRows = 0;
      this.savedStatus = false;
      this.initializeDropzone();
      this.checkValidation();

      // Handle automatic update when new elements are added to the DOM
      this.observeMutations();
      this.openUpload();
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  openUpload() {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get("source"); // Retrieve the 'source' parameter
    this.uploadTypeValue = source || this.defaultTypeValue || 'csv';
    this.selectedItem = uploadItems[this.uploadTypeValue || "csv"];
    this.showActiveSection();
    this.radioTargets.forEach(e => e.checked = e.value == this.uploadTypeValue);
    this.modal.show();
  }

  updateSourceParam(newSourceValue) {
    const url = new URL(window.location);
    url.searchParams.set("source", newSourceValue); // Update or add the 'source' parameter

    // Update the URL in the browser without reloading the page
    window.history.replaceState({}, "", url);
  }

  radioTargetConnected(radio) {
    try {
      radio.addEventListener("click", this.select.bind(this));
    } catch (error) {
      console.error("Error in radioTargetConnected:", error);
    }
  }

  radioTargetDisconnected(radio) {
    try {
      radio.removeEventListener("click", this.select.bind(this));
    } catch (error) {
      console.error("Error in radioTargetDisconnected:", error);
    }
  }

  inputTargetConnected(input) {
    try {
      input.addEventListener("input", this.checkValidation.bind(this));
    } catch (error) {
      console.error("Error in radioTargetDisconnected:", error);
    }
  }

  inputTargetDisconnected(input) {
    try {
      input.removeEventListener("input", this.checkValidation.bind(this));
    } catch (error) {
      console.error("Error in radioTargetDisconnected:", error);
    }
  }

  urlTargetConnected(input) {
    try {
      input.addEventListener("input", this.checkValidation.bind(this));
    } catch (error) {
      console.error("Error in radioTargetDisconnected:", error);
    }
  }

  urlTargetDisconnected(input) {
    try {
      input.removeEventListener("input", this.checkValidation.bind(this));
    } catch (error) {
      console.error("Error in radioTargetDisconnected:", error);
    }
  }

  submitButtonTargetConnected(button) {
    try {
      button.addEventListener('click', this.upload.bind(this));
    } catch (error) {
      console.error("Error in submitButtonTargetConnected:", error);
    }
  }

  submitButtonTargetDisconnected(button) {
    try {
      button.removeEventListener('click', this.upload.bind(this));
    } catch (error) {
      console.error("Error in submitButtonTargetDisconnected:", error);
    }
  }

  upload(event) {
    try {
      if (this.availableCreditsValue == 0) {
        this.alertType = 'error'
      } else if (this.availableCreditsValue < this.totalRows){
        this.alertType = 'warning'
      }

      if (this.alertType) {
        event.preventDefault();
        this.alert.show()
      }

    } catch (error) {
      console.error("Error in upload:", error);
    }
  }

  uploadDone(event) {
    try {
      if (this.savedStatus) {
        this.dropzone.removeAllFiles(true); // true removes files from Dropzone UI immediately
        this.resetTable();
      }
    }  catch (error) {
      console.error("Error in uploadDone:", error);
    }
  }

  // Automatically update lazy load when new elements are added to the DOM
  observeMutations() {
    const that = this;
    this.mutationObserver = new MutationObserver((mutations) => {
      that.savedStatus = false;
      mutations.forEach((mutation) => {
        // Check if nodes were added to the DOM
        mutation.addedNodes.forEach((node) => {
          // Verify if the added node is an element and has the ID 'alertError'
          if (node.nodeType === Node.ELEMENT_NODE && node.id === "alertError") {
            that.savedStatus = false;
            return;
          }
          if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('upload-completed')) {
            that.savedStatus = true;
            return;
          }
        });
      });

      if (that.savedStatus) {
        this.resetTable();
        this.dropzone.removeAllFiles(true);
        this.modal.hide();
      }
    });

    this.mutationObserver.observe(this.element, {
      childList: true,
      subtree: true
    });
  }

  select(event) {
    try {
      this.uploadTypeValue = event.target.value;
      this.showActiveSection();
      if (event.target.checked) {
        this.selectedItem = uploadItems[this.uploadTypeValue || "csv"];
        this.updateSourceParam(this.uploadTypeValue)

        if (!this.isVerifiedValue) {
          this.alertType = 'verify'
        } else if (this.availableCreditsValue <= 0) {
          this.alertType = 'credit'
        }

        if (this.alertType) {
          this.alert.show();
        } else {
          this.modal.show();
        }
      }
    } catch (error) {
      console.error("Error in select:", error);
    }
  }

  openList() {
    try {
      this.listModal.show()
    } catch (error) {
      console.error("Error in select:", error);
    }
  }

  closeList(event) {
    try {
      this.listModal.hide()
    } catch (error) {
      console.error("Error in select:", error);
    }
  }

  checkValidation() {
    try {
      let isValid = false;

      if (this.uploadTypeValue === "csv") {
        // Check if a file has been added to Dropzone
        isValid = this.dropzone.files.length > 0;
      } else if (this.uploadTypeValue === "manually") {
        // Check if manual input has data
        isValid = this.inputTarget.value.trim().length > 0;
      } else if (this.uploadTypeValue === "url") {
        // Check if manual input has data
        isValid = this.urlTarget.value.trim().length > 0;
      }

      // Enable or disable the submit button based on validation
      this.submitButtonTarget.disabled = !isValid;
    } catch (error) {
      console.error("Error in checkValidation:", error);
    }
  }

  showActiveSection() {
    try {
      // Hide all upload sections
      this.csvSectionTarget.classList.add("hidden");
      this.manuallySectionTarget.classList.add("hidden");
      this.urlSectionTarget.classList.add("hidden");
      this.sourceTarget.value = this.uploadTypeValue;

      // Show the selected section based on the uploadTypeValue
      switch (this.uploadTypeValue) {
        case "csv":
          // this.previewTarget.classList.remove("hidden");
          this.csvSectionTarget.classList.remove("hidden");
          this.inputTarget.disabled = false
          this.urlTarget.disabled = true
          break;
        case "manually":
          // this.previewTarget.classList.remove("hidden");
          this.manuallySectionTarget.classList.remove("hidden");
          this.inputTarget.disabled = false
          this.urlTarget.disabled = true
          break;
        case "url":
          this.urlSectionTarget.classList.remove("hidden");
          this.previewTarget.classList.add("hidden");
          this.inputTarget.disabled = true
          this.urlTarget.disabled = false
          break;
        default:
          console.warn("Unknown upload type selected");
      }
    } catch (error) {
      console.error("Error in showActiveSection:", error);
    }
  }

  showAlertSection() {
    try {
      // Hide all upload sections
      this.alertTarget.classList.add("hidden");
      this.warningTarget.classList.add("hidden");
      this.errorTarget.classList.add("hidden");

      // Show the selected section based on the uploadTypeValue
      switch (this.alertType) {
        case "error":
          this.errorTarget.classList.remove("hidden");
          break;
        case "warning":
          Array.from(document.querySelectorAll('.credits')).forEach(e => e.textContent = this.availableCreditsValue);
          this.totalTarget.textContent = this.availableCreditsValue;
          this.creditTarget.textContent = this.totalRows;
          this.progressTarget.style.width = `${(this.availableCreditsValue/this.totalRows) * 100}%`
          this.warningTarget.classList.remove("hidden");
          break;
        default:
          this.alertTarget.classList.remove("hidden");
      }
    } catch (error) {
      console.error("Error in showActiveSection:", error);
    }
  }

  close(event) {
    try {
      this.resetTable();
      this.dropzone.removeAllFiles(true);
      this.modal.hide();
    } catch (error) {
      console.error("Error in close:", error);
    }
  }

  closeAlert(event) {
    try {
      this.alert.hide();
    } catch (error) {
      console.error("Error in close:", error);
    }
  }

  initModal() {
    try {
      const $targetEl = document.getElementById("uploadModal");

      const options = {
        placement: "bottom-right",
        backdrop: "dynamic",
        backdropClasses:
          "upload-modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
        closable: true,
        onHide: () => {
          document.querySelectorAll(".upload-modal-backdrop").forEach((element) => {
            element.remove();
          });
          this.previewTarget.classList.add('hidden')
        },
        onShow: () => {
          this.modalTarget.querySelector("h3").textContent = this.selectedItem.title;
        }
      };

      return new Modal($targetEl, options, { id: "uploadModal", override: true });
    } catch (error) {
      console.error("Error in initModal:", error);
    }
  }

  initAlertModal() {
    try {
      const that = this;
      const $targetEl = document.getElementById("alertModal");

      const options = {
        placement: "bottom-right",
        backdrop: "dynamic",
        backdropClasses:
          "alert-modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
        closable: true,
        onHide: () => {
          document.querySelectorAll(".alert-modal-backdrop").forEach((element) => {
            element.remove();
          });
          if (this.modalTarget) {
            this.modalTarget.classList.add('z-50');
            this.modalTarget.classList.remove('z-30');
          }
        },
        onShow: () => {
          that.showAlertSection();
          that.alertMessageTarget.innerHTML = alertMessages[that.alertType];
          if (this.modalTarget) {
            this.modalTarget.classList.remove('z-50');
            this.modalTarget.classList.add('z-30');
          }
        }
      };

      return new Modal($targetEl, options, { id: "alertModal", override: true });
    } catch (error) {
      console.error("Error in initModal:", error);
    }
  }

  initListModal() {
    try {
      const that = this;
      const $targetEl = document.getElementById("addListModal");

      const options = {
        placement: "bottom-right",
        backdrop: "dynamic",
        backdropClasses:
          "add-list-modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
        closable: true,
        onHide: () => {
          document.querySelectorAll(".add-list-modal-backdrop").forEach((element) => {
            element.remove();
          });
          if (this.modalTarget) {
            this.modalTarget.classList.add('z-50');
            this.modalTarget.classList.remove('z-30');
          }
        },
        onShow: () => {
          that.showAlertSection();
          that.alertMessageTarget.innerHTML = alertMessages[that.alertType];
          if (this.modalTarget) {
            this.modalTarget.classList.remove('z-50');
            this.modalTarget.classList.add('z-30');
          }
        }
      };

      return new Modal($targetEl, options, { id: "addListModal", override: true });
    } catch (error) {
      console.error("Error in initModal:", error);
    }
  }

  initializeDropzone() {
    try {
      const that = this;

      this.dropzone = new Dropzone(this.dropzoneTarget, {
        url: "/", // No actual upload
        autoProcessQueue: false,
        acceptedFiles: ".csv",
        maxFiles: 1,
        previewTemplate: document.getElementById("file-preview-template").innerHTML,
        clickable: true,
        dictDefaultMessage: "",
        init: function () {
          const message = that.dropzoneTarget.querySelector(".dz-message");
          if (message) {
            message.querySelector('button').textContent = "Drag & drop a CSV file here or click to upload"
          }

          this.on("addedfile", (file) => that.handleFileAdded(file));
          this.on("removedfile", () => {
            that.resetTable(); // Reset table on file removal
            that.checkValidation(); // Check validation after file removal
          });
        }
      });
    } catch (error) {
      console.error("Error in initializeDropzone:", error);
    }
  }

  async handleFileAdded(file) {
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      alert("Only CSV files are allowed.");
      this.dropzone.removeFile(file);
      return;
    }

    const progressBar = file.previewElement.querySelector("[data-dz-uploadprogress]");
    const progressPercent = file.previewElement.querySelector("[data-dz-uploadprogresspercent]");
    progressBar.style.width = "0%"; // Reset progress bar
    progressPercent.textContent = "0%"; // Reset progress bar

    // Read the file as text
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvContent = e.target.result;
      const rows = csvContent.trim().split("\n").slice(1).map((row) => row.split(","));
      const lineCount = rows.length;
      this.updateLineCounter(lineCount);
      this.inputTarget.value = csvContent.trim().split("\n").slice(1).join("\n");

      // Update progress as each row is processed
      const totalRows = rows.length;
      for (let i = 1; i <= totalRows; i++) { // Start from 1 to skip header
        // Simulate processing delay for demonstration
        await this.simulateProcessing(10); // 10ms delay for each row

        // Update progress percentage
        const progress = ((i / totalRows) * 100);
        progressBar.style.width = `${progress}%`;
        progressPercent.textContent = `${progress}%`;
      }

      if (lineCount > 500) {
        this.showErrorMessage();
        this.dropzone.removeFile(file);
      } else {
        this.hideErrorMessage();
        const parsedData = rows.map((row) => this.parseRow(row));
        this.renderTable(parsedData);
      }

      // Call any additional processing here, if needed
      this.checkValidation(); // Check validation after reading the file
    };

    reader.readAsText(file);
  }

  simulateProcessing(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  resetTable() {
    // Clear the table body
    this.tableBodyTarget.innerHTML = "";
    this.totalRows = 0;
    this.updateLineCounter(0);
    this.inputTarget.value = '';
    this.urlTarget.value = '';
    this.previewTarget.classList.add('hidden');
  }

  parseUrls() {
    try {
      const urlData = this.urlTarget.value.trim();

      if (!urlData) {
        return;
      }

      const rows = urlData.split("\n").map((row) => row.split(","));
      const lineCount = rows.length;
      this.updateLineCounter(lineCount);
      this.totalRows = lineCount;

    } catch (error) {
      console.error("Error in parseCsv:", error);
    }
  }

  parseCsv() {
    try {
      const csvData = this.inputTarget.value.trim();

      if (!csvData) {
        return;
      }

      const rows = csvData.split("\n").map((row) => row.split(","));
      const lineCount = rows.length;
      this.updateLineCounter(lineCount);

      if (lineCount > 500) {
        this.showErrorMessage();
      } else {
        this.hideErrorMessage();
        const validRows = rows.map((row) => this.parseRow(row));
        this.renderTable(validRows);
      }
    } catch (error) {
      console.error("Error in parseCsv:", error);
    }
  }

  parseRow(row) {
    try {
      const [firstName = "", lastName = "", company = "", title = ""] = row.map((cell) => cell.trim());
      return { firstName, lastName, company, title };
    } catch (error) {
      console.error("Error in parseRow:", error);
    }
  }

  renderTable(dataRows) {
    try {
      this.totalRows = dataRows.length;

      if (dataRows.length > 0) {
        this.previewTarget.classList.remove('hidden')
      } else {
        this.previewTarget.classList.add('hidden')
      }

      this.tableBodyTarget.innerHTML = "";

      dataRows.forEach(({ firstName, lastName, company, title }) => {
        const tr = document.createElement("tr");

        tr.innerHTML = `
          <td class="p-2 border-b text-gray-600">${firstName}</td>
          <td class="p-2 border-b text-gray-600">${lastName}</td>
          <td class="p-2 border-b text-gray-600">${company}</td>
          <td class="p-2 border-b text-gray-600">${title}</td>
        `;
        this.tableBodyTarget.appendChild(tr);
      });
    } catch (error) {
      console.error("Error in renderTable:", error);
    }
  }

  updateLineCounter(lineCount) {
    try {
      this.lineCountTarget.textContent = lineCount;
    } catch (error) {
      console.error("Error in updateLineCounter:", error);
    }
  }

  showErrorMessage() {
    try {
      this.messageTarget.classList.remove("hidden");
      this.lineCounterTarget.classList.add("text-red-500");
    } catch (error) {
      console.error("Error in showErrorMessage:", error);
    }
  }

  hideErrorMessage() {
    try {
      this.messageTarget.classList.add("hidden");
      this.lineCounterTarget.classList.remove("text-red-500");
    } catch (error) {
      console.error("Error in hideErrorMessage:", error);
    }
  }

  toggleSubmitButton(button) {
    try {
      const originalContent = button.innerHTML;
      const loadingContent = button.getAttribute('data-turbo-submits-with');

      if (loadingContent) {
        button.setAttribute('data-turbo-submits-with', originalContent);
        button.innerHTML = loadingContent;
      }

      button.disabled = !button.disabled; // Optionally disable the button
    } catch (error) {
      console.error("Error in toggleSubmitButton:", error);
    }
  }
}
