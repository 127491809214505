import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ["checkbox", "checkboxAll", "fromList", "toList"]

  connect() {
    super.connect()
    this.toggleCheckedHidenInput = this.toggleCheckedHidenInput.bind(this)
  }

  toggle(event) {
    super.toggle(event)
    if (event.target == this.checkboxAllTarget) {
      this.checked.forEach(e => this.toggleCheckedHidenInput(e))
      this.unchecked.forEach(e => this.toggleCheckedHidenInput(e))
      this.toggleCheckedHidenInput(event.target)
    }
  }

  refresh(event) {
    super.refresh()
    if (event) {
      this.toggleCheckedHidenInput(event.currentTarget)
    }
  }

  toggleButton(button) {
    if (button) {
      if (this.checked.length > 0) {
        button.classList.remove("opacity-65", "cursor-not-allowed")
        button.disabled = false
      } else {
        button.classList.add("opacity-65", "cursor-not-allowed")
        button.disabled = true
      }
    }
  }

  added(e) {
    if (e.detail.success) {
      e.target.closest('#addListModal').querySelector('[data-modal-hide]').click()
    }
  }

  updated(e) {
    if (e.detail.success) {
      const submitter = e.detail.formSubmission.submitter
      if (submitter) {
        if (submitter.value == 'delete') {
          this.checkboxAllTarget.checked = false
          this.refresh()
        }

        this.toggleCheckedHidenInput(this.checkboxAllTarget)
      }
    }
  }

  changeList(e) {
    const $formTarget = e.target.closest('form')
    if ($formTarget) {
      $formTarget.setAttribute('action', e.target.dataset.callbackUrl.replace(/__ID__/, e.target.value))
      $formTarget.requestSubmit()
    }
  }

  loaded(e) {
    if (e.detail.success) {
      history.pushState({}, null, e.target.action);
    }
  }

  merged(e) {
    if (e.detail.success) {
      e.target.closest('#mergeListModal').querySelector('[data-modal-hide]').click()
    }
  }

  toListTargetConnected(select){
    select.addEventListener('change', this.cascaded.bind(this))
  }

  fromListTargetConnected(select){
    select.addEventListener('change', this.cascaded.bind(this))
  }

  cascaded(e) {
    const $cascadedList = e.target.id == 'from_list_id' ? this.toListTarget : this.fromListTarget
    if($cascadedList) {
      const $currentSelected = $cascadedList.querySelector('option[disabled]')
      if($currentSelected) {
        $currentSelected.disabled = false
      }

      const $cascadedSelected = $cascadedList.querySelector(`option[value="${e.target.value}"]`)
      if ($cascadedSelected) {
        $cascadedSelected.disabled = true
      }
    }
  }

  toggleCheckedHidenInput(element) {
    const $formTarget = document.querySelector(element.dataset.formTarget)
    const $toggleTarget = document.querySelector(element.dataset.toggleTarget)

    if ($formTarget) {
      const signedId = element.value
      const $submitButton = $formTarget.querySelector(`[type="submit"]`)
      this.toggleButton($submitButton)

      if (element.checked) {
        if (signedId) {
          const hiddenField = document.createElement("input")
          hiddenField.setAttribute("type", "hidden")
          hiddenField.setAttribute("name", element.name)
          hiddenField.setAttribute("value", signedId)
          hiddenField.setAttribute("data-selected-id", signedId)
          $formTarget.appendChild(hiddenField)
        }
      } else {
        const hiddenFields = $formTarget.querySelectorAll(`input[type="hidden"][data-selected-id="${signedId}"]`)
        if (hiddenFields) {
          hiddenFields.forEach(e => e.remove())
        }
      }
    }

    if ($toggleTarget) {
      const that = this
      setTimeout(function(){
        if (that.checked.length > 0) {
          $toggleTarget.classList.remove('hidden')
        } else {
          $toggleTarget.classList.add('hidden')
        }
      }, 100)
    }
  }
}
