import { Controller } from "@hotwired/stimulus"
import ShowMore from "../helpers/show_more"

export default class extends Controller {
  static targets = ["input", "alert", "domain"]

  initialize() {
    this.resultTarget = document.getElementById('results')
    this.domainAndUrlRegEx = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
    this.inputTargets.forEach(input => {
      input.addEventListener("focus", this.focus.bind(this))
      input.addEventListener("blur", this.blur.bind(this))
    })

    new ShowMore('.element', {
      onMoreLess: (type, object) => {}
    });
  }

  focus() {
    this.element.classList.add("active")
  }

  blur() {
    if (this.inputTargets.every(input => input !== document.activeElement)) {
      this.element.classList.remove("active")
    }
  }
}
